@import '../styles/_Variables.scss';
@import '../styles/_Fonts.scss';

/* Service Carousel Phases Styles */
.phases {
    display: flex;
    position: relative;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 0.8em;
    width: 100%;
    justify-content: space-around;

    &::before {
        display: block;
        position: absolute;
        width: 100%;
        border-top: 4px dotted grey;
        top: 50%;
        content: "";
    }

    .phase-content {
        margin: auto;
    }

    .phase-container {
        position: relative;
        z-index: 1;
        display: flex;
        font-size: 0.8em;
        width: 100%;
        justify-content: space-around;

        .phase-path {
            display: flex;
            align-items: center;

            i {
                font-size: 0.5em;
            }
        }

        @media (max-width: $medium-screen-size) {
            .label {
                display: none;
            }

            .phase-path {
                i {
                    font-size: 0.3em;
                }
            }
        }

        .phase {
            height: 6.5em;
            width: 6.5em;
            border-radius: 50%;
            background: black;
            color: white;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: 0.3s;
        }

        &.active {
            @extend %text-bold;
            $active-color: orange;

            .phase-path {
                color: $active-color;
            }

            .phase {
                background: $active-color;
                transform: scale3d(1.2, 1.2, 1);
            }
        }

        &.done {
            $done-color: grey;

            .phase-path {
                color: $done-color;
            }

            .phase {
                background: $done-color;
            }
        }
    }
}
