@import '../styles/_Variables.scss';

/* Footer */
.footer {
    position: relative;
    padding: 3em 0 1.5em;
    color: $white;
    background-position: 50% 30%;
    background-size: cover;

    @media (max-width: $medium-screen-size) {
        padding: 2em 0 1.2em;
    }
}

.footer-content {
    position: relative; /* Ensure content is above the particles */
    z-index: 1;
    word-break: break-word;

    .footer-title {
        margin-bottom: 0.5em;
    }

    .footer-contact {
        margin-bottom: 0.1em;
        font-size: 1.2em;

        @media (max-width: $medium-screen-size) {
            font-size: 1em;
        }
    }

    .footer-label {
        opacity: 0.5;
    }

    .footer-about {
        margin-top: 2em;

        .footer-about-text {
            line-height: 1.1em;
        }
    }

    .footer-copyright {
        margin-top: 2em;
        align-self: flex-end;
        font-size: 0.65em;
    }
}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure particles are behind the content */
    pointer-events: none;
}
