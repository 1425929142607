@import '../styles/_Variables.scss';

/* Service Carousel Styles */
.service .slides {
    .carousel-content {
        padding-left: 15px;
        padding-right: 15px;

        transition: 0.5s;
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
    }

    .slick-active .carousel-content {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
    
    .carousel {
        .slick-prev, .slick-next {
            display: none !important;
        }
    }

    .img-wrapper {
        display: none;
    }

    .content-wrapper ul {
        margin-bottom: 0;
    }

    @media (min-width: 992px) {
        .content-wrapper {
            margin-top: 1em;
            font-size: 0.8em;
        }
        .img-wrapper {
            display: block;
            margin-top: 1em;
        }
    }
}
