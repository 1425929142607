@import '../styles/_Variables.scss';
@import '../styles/_Fonts.scss';

/* Service Content Styles */
.service-container {
    section {
        margin-top: 2.5em;

        p {
            margin-bottom: 1em;
        }
    }

    .service-title {
        margin-top: 1.5em;
        margin-bottom: 1em;

        @media (max-width: $medium-screen-size) {
            font-size: inherit;
        }
    }

    .service-content {
        img {
            width: 100%;
            margin-bottom: 1em;
        }

        ul {
            padding-left: 1em;
            margin-top: 1em;
        }
    }

    .service-quotes {
        margin-top: 2em;
        margin-bottom: 2em;
        text-align: center;
    }

    .service-pointers li {
        @extend %text-bold;
        margin-top: 0.3em;
        font-size: 0.8em;

        &:last-child {
            margin-bottom: 3em;
        }

        @media (max-width: $medium-screen-size) {
            font-size: 0.9em;
        }
    }

    .service-case-study {
        position: relative;
        height: 350px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        margin-top: 4em;
        margin-bottom: 1em;

        // Full width
        @media (max-width: $small-screen-size) {
            right: 50%;
            left: 50%;
            width: 100vw;
            margin-right: -50vw;
            margin-left: -50vw;
        }

        &::before {
            display: block;
            position: absolute;
            background-color: #111;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            content: "";
        }

        .service-case-study-content {
            position: absolute;
            z-index: 2;
            bottom: 0;
            padding: 1em;
        }
    }

    .sub-point {
        @extend %text-regular;
        display: block;
        padding-left: 1em;
        margin-top: 0.5em;
        margin-bottom: 1.2em;
        color: #555;

        &::before {
            margin-left: -0.8em;
            content: "- "
        }
    }
}

.service-img-wrapper {
    margin-top: 4em;
    margin-bottom: 3em;
}

.more-services-wrapper {
    margin-top: 3em;
}
