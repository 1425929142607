@import '../styles/_Fonts.scss';
@import '../styles/_Variables.scss';

/* Team Styles */
.team {
    padding: 0;

    .team-member {
        padding-top: 1em;
        border-bottom: 1px solid #dddddd;
        margin-right: 0;
        margin-left: 0;

        &.has-image {
            padding-top: 2em;
            padding-bottom: 2em;
        }

        .team-position {
            @extend %text-bold;
            text-transform: uppercase;
            font-size: 0.7em;
            margin-bottom: 1.2em;
            letter-spacing: 1px;
        }

        .team-profile-image {
            margin-bottom: 1.5em;
            width: 100%;

            @media (max-width: $medium-screen-size) {
                margin-bottom: 1.5em;
            }
        }

        .profile-desc {
            font-size: 0.85em;
            white-space: pre-wrap;
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 3.5em;
        }
    }
}

