// Helpers
.h-fade { opacity: 0.6; }
.h-fader { opacity: 0.4; }

// Heading sizes
@each $header, $size in $header-sizes {
    #{$header}, .#{$header} {
      font-size: $size * 1em !important;

        @media (max-width: $medium-screen-size) {
            font-size: $size * 0.65em !important;
        }
    }
}

// Useful sizes
.text-huge { font-size: 4em }
.text-large { font-size: 3.8em; }
.text-bigger { font-size: 1.2em; line-height: 1.55em; }
.text-big { font-size: 1.05em; }

@media (max-width: $medium-screen-size) {
    .text-huge { font-size: 3.2em }
    .text-large { font-size: 3em; }
    .text-big { font-size: 1.1em; }
}

p {
    @extend %text-regular;
    margin-bottom: 0.5em;
    font-size: inherit;
    line-height: 1.4;
    text-rendering: optimizeLegibility;
}

.page-title {
    @extend .text-bolder;
    margin-bottom: 0.65em;
    font-size: 2em;

    @media (max-width: $medium-screen-size) {
        font-size: 1.4em;
    }
}

.wrap-left {
    position: relative;
    left: 2em;

    @media (max-width: $medium-screen-size) {
        left: auto;
    }
}

.hide {
    display: none;
}

.overlay {
    height: 100%;
    width: 100%;
    background: rgba($black, 0.4);
    z-index: 1;
}

.screen-height {
    $bottom-padding: 50px;

    height: calc(100vh - #{$navbar-height} - #{$bottom-padding});
    min-height: 250px;
    max-height: 300px;

    &.intro {
        max-height: 650px;
    }

    @media (max-width: $medium-screen-size) {
        height: calc(100vh - #{$navbar-small-height} - #{$bottom-padding});
    }
}

.intro-overlap {
    $content-min-height: 3.5em;
    
    position: relative; /* Ensure it creates a new stacking context */
    z-index: 2; /* Higher than overlay, lower than text */
    overflow-x: hidden;

    overflow-x: hidden;
    padding: 0;
    min-height: calc(#{$content-min-height});
    margin-top: calc(-#{$content-min-height} + 1px);
    margin-bottom: 2em;
    background-color: $white;

    .intro-content {
        margin-top: 2em;
        margin-bottom: 3em;
    }
}
