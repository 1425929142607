@import '../styles/_Variables.scss';

/* Approach Carousel Styles */
@media (min-width: $medium-screen-size + 1px) {
    .approach .slides {
        .carousel {
            width: 70%;
        }
    }
}
