@import '../styles/_Variables.scss';

#app {
    padding-top: $navbar-height;

    @media (max-width: $medium-screen-size) {
        padding-top: $navbar-small-height;
    }
}

// Overwrite bootstrap container
.container {
    max-width: 1200px;
    width: auto;

    // remove x padding
    padding-right: 0;
    padding-left: 0;

    &.has-header, &.navbar-container, &.footer-container, .footer & {
        max-width: 100%;
    }

    @media (max-width: $medium-screen-size) {
        &.has-header, &.footer-container, &.navbar-border-container.navbar-border-container,
        &.card-list,  &.card-container {
            padding: 0;
        }

        .slides &, .gallery & {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
