@import '../styles/_Variables.scss';

/* Gallery Styles */
.gallery {
    margin: 0 auto;
    width: 60%;
    height: 100%;

    @media (max-width: $medium-screen-size) {
        width: 100%;
    }

    .gallery-container {
        display: flex;
        flex-wrap: wrap;

        img {
            padding: 0.1em;
        }
    }

    .home & {
        margin-top: 3em;
        margin-bottom: 4.5em;
    }
}
