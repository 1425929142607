@import '../styles/_Variables.scss';

.hero-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .hero-title {
        margin-top: -1em;
        align-self: center;
        text-align: center;

        @media (max-width: $medium-screen-size) {
            margin-top: -2em;
        }
    }
}

.no-content {
    min-height: 20vh;
}
