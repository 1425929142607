@import '../styles/_Variables.scss';

/* Clients Styles */
.clients {
    height: auto;
    padding-bottom: 4.5em;

    .clients-desc {
        margin-bottom: 3em;
    }

    .client-logo {
        [class^="col-"] {
            margin-bottom: 1em;
        }

        img {
            width: 100%;
            position: relative;
            top: 50%;
            transform: perspective(1px) translate(0, -50%);
        }

        @media (max-width: $medium-screen-size) {
            padding: 0 1em;

            img {
                width: 100%;
            }
        }
    }
}
