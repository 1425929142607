@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i,800|Oswald:300,600|Reenie+Beanie');

/* Fonts */
.header-regular, %header-regular {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
}
.header-bold, %header-bold {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 600;
}
.text-regular, %text-regular {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: 400;
}
.text-italic, %text-italic, em, i {
    font-family: 'Muli', sans-serif;
    font-style: italic;
    font-weight: 400;
}
.text-bold, %text-bold, strong, b {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: 700;
}
.text-bolditalic, %text-bolditalic {
    font-family: 'Muli', sans-serif;
    font-style: italic;
    font-weight: 700;
}
.text-bolder, %text-bolder {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: 800;
}
.postit-regular, %postit-regular {
    font-family: "Reenie Beanie", sans-serif;
    font-style: normal;
    font-weight: 400;
}
.postit-bold, %postit-bold {
    font-family: "Reenie Beanie", sans-serif;
    font-style: normal;
    font-weight: 700;
}
