@import '../styles/_Variables.scss';

/* Banner Styles */
.banner {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    .banner-text {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

        &.intro .banner-text {
            display: block;
        }

    &.footer .banner-text,
    &.services .banner-text,
    &.approach .banner-text,
    &.gallery .banner-text {
        padding-top: 4em;
        padding-bottom: 4em;
    }

    @media (max-width: $medium-screen-size) {
        &.footer .banner-text,
        &.services .banner-text,
        &.approach .banner-text,
        &.gallery .banner-text {
            padding-top: 3em;
            padding-bottom: 3em;
        }

        &.services  {
            height: auto;
        }
    }
}
