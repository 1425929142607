@import '../styles/_Variables.scss';

/* Card Styles */
.card-container {
    height: 30em;
    display: flex;
    padding: 1em;
    align-items: center;

    .card-background {
        height: 100%;
        width: 100%;
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .card-content {
            width: 80%;
            margin-left: 0.5em;
            margin-bottom: 0.5em;
            max-height: 100%;
            overflow: hidden;
            align-self: flex-end;
            padding: 1em 1em;
            background-color: rgba(255, 255, 255, 0.95);

            .card-title {
                margin-bottom: 0.6em;

                @media (max-width: $medium-screen-size) {
                    font-size: 2em;
                }
            }

            a {
                display: block;
                margin-top: 1em;
                font-size: 0.9em;
                line-height: 1.4em;
            }
        }

        @media (min-width: $medium-screen-size) {
            .card-content {
                padding: 1em;
            }
        }

        @media (max-width: $medium-screen-size) {
            .card-content {
                width: 88%;
            }

            .card-content-desc {
                font-size: 1.2em;
                line-height: 1.5em;
            }
        }
    }
}

.cardlink {
    position: relative;
    display: block;
    padding-left: 1.1em;
    font-size: 2em;

    &::before {
        display: block;
        background-image: url("../assets/images/arrow-right2.png");
        background-repeat: no-repeat;
        background-position: 0 6px;
        background-size: 16px 16px;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 21px;
        content: "";
        z-index: 2;
    }


    @media (max-width: $medium-screen-size) {
        &::before {
            background-size: 14px 14px;
        }
    }
}

@media (max-width: $medium-screen-size) {
    .card-container, .card-list {
        padding: 0;
    }
}
