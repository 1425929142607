@import '../styles/_Variables.scss';

.intro-video-container {
	width: 100%;

	.filters {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.dot-filter {
		display: none;
		background: url('../assets/images/dot-filter.png');
	}

	.filter {
        background: linear-gradient(45deg, transparentize($black, 0.2), transparentize($black, 0.8));
	}

	video {
        min-height: 100%;
		min-width: 100%;
		object-fit: cover;

        left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
    }
}