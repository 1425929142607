@import '../styles/_Variables.scss';

/* Article Styles */
.article {
    .banner {
        @media (max-width: $medium-screen-size) {
            background-size: 100%;
            background-position: 0 30%;
        }
    }

    .img-wrapper {
        margin-top: 3.2em;
        margin-bottom: 2.2em;
    }

    .subtitle {
        margin-top: 2em;
        margin-bottom: 1em;
    }

    p {
        margin-bottom: 1.4em;
    }
}

.articles .page-title {
    @media (min-width: $large-screen-size) {
        min-height: 3em;
    }
}

.article-quotes {
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.articles-wrapper {
    margin-right: 1em;
    margin-left: 1em;

    @media (min-width: $large-screen-size) {
        margin-right: 0;
        margin-left: 0;

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
