/* Article Card Styles */
.article-card {
    display: flex;
    flex: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .author-image img {
        width: 80%;
        border-radius: 50%;
    }

    .author-name {
        margin-bottom: 0;
        line-height: 1.4em;
    }

    > a {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .article-card-img {
        height: 180px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .article-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .article-card-title {
            margin-top: 0.6em;
            line-height: 1.3;
        }

        .article-card-desc {
            margin-top: 0.8em;
            line-height: 1.6;
            font-size: 0.95em;
            flex: 1;
        }

        .article-card-author {
            display: flex;
            margin-top: 1.4em;
        }
    }
}
