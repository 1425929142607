@import '../styles/_Variables.scss';

/* Carousel Styles */
.slides {
    height: 100%;
    margin: 0 auto;
    text-align: center;

    .carousel {
        width: 95%;

        .carousel-content {
            padding-left: 0;
            pointer-events: none;

            .carousel-img {
                position: relative;
                margin-top: 2em;
                max-width: 500px;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }

                @media (max-width: $small-screen-size) {
                    max-width: 100%;
                }
            }
        }

        .slick-prev, .slick-next {
            width: auto;
            height: auto;
            z-index: 3;

            &:before {
                opacity: 0.15;
                color: $black;
                font-size: 50px;

                @media (max-width: $small-screen-size) {
                    font-size: 45px;
                }
            }
        }

        .slick-prev { left: 15%; }
        .slick-next { right: 15%; }
        .slick-prev:hover, .slick-next:hover {
            &:before {
                opacity: 0.9;
            }
        }
    }
}

// Small Devices
@media (max-width: $small-screen-size) {
    .slides {
        font-size: 0.8em;

        .carousel {
            width: 100%;
        }

        .carousel-content {
            padding-left: 15px;
        }

        .slick-prev.slick-prev { left: 0; }
        .slick-next.slick-next { right: 0; }
    }
}
