/* Imports */
$fa-font-path: '~font-awesome/fonts';

@import '~font-awesome/scss/font-awesome.scss';
@import '~normalize.css/normalize.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~animate.css/animate.css';
@import '../styles/_Variables.scss';
@import '../styles/_Fonts.scss';
@import '../styles/_Helper.scss';
@import '~bootstrap/scss/bootstrap.scss';

body {
    --small-screen-size: $small-screen-size;
    --medium-screen-size: $medium-screen-size;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @extend %text-bold;
    margin-bottom: 0.2em;
    color: #111111;
    line-height: 1.35;
}

/* Base Application Styles */
body, html {
	font-family: 'Muli', sans-serif;
	font-size: 1.1em;
    color: #111111;

    @media (max-width: $small-screen-size) {
       font-size: 1.05em;
    }
}

/* Loader Animation Override */
.loader {
    animation-duration: 0.5s;
}

small, .small {
    font-size: 0.85em;
}

a {
    @extend .orange;

    &:hover {
        text-decoration: none;
    }
}

p {
    line-height: 1.7em;
}

input, textarea, button,
select, label, a, svg {
  -webkit-tap-highlight-color: transparent;
}
button:focus {
    outline: 0;
}

.white {
    color: $white;
}

.black {
    color: $black;
}

.orange {
    color: $orange;
}

.brown {
    color: $brown;
}

.grey {
    color: $grey;
}
