@import '../styles/_Fonts.scss';
@import '../styles/_Variables.scss';

$yellow: map-get($post-it, yellow);
$light-yellow: map-get($post-it, light-yellow);

.testimonials {
	.postit-wrapper {
		transform: rotate(-2deg);
		padding: 1em;

		.postit-container {
			background: linear-gradient(135deg, $yellow 81%, $yellow 82%, $yellow 82%, $light-yellow 100%); ;
			padding:1em;
			border-bottom-right-radius: 60px 5px;

			&:after {
				content: "";
				position: absolute;
				z-index: -1;
				right: 31px;
				bottom: 31px;
				width: 50%;
				max-width: 200px;
				height: 25px;
				background: rgba(0, 0, 0, 0.2);
				box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.40);
				transform: matrix(-1, -0.1, 0, 1, 0, 0);
			}

			.postit-content {
				@extend .postit-bold;
				font-size: 1.5em;
			}

			.postit-author {
				@extend .postit-regular;
				font-size: 1.2em;
				padding-right: 3em;
			}
		}
	}

	.testimonials-quote-icon {
		position: absolute;
		right: 2.5em;
		bottom: 2em;
		font-size: 1.5em;
	}
}