@import '../styles/_Variables.scss';

/* Intro Styles */
@keyframes flicker {
  0%   { opacity: 0.65; }
  100% { opacity: 0.9; }
}

.intro {
    position: relative;
    background-color: $black;
    color: $white;

    .intro-bg {
        position: absolute;
        bottom: -1.2em;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;

        img {
            margin-top: 0;
            width: auto;
            height: 600px;
            opacity: 0.8;
            animation: flicker 3s infinite;
            animation-direction: alternate;
        }
    }

    @media (max-width: $large-screen-size) {
        border: none;

        & .banner-text.banner-text {
            align-items: flex-end;
        }
    }
}

.intro-clients {
    position: absolute;
    width: 100%;
    bottom: 2em;
    margin-top: 1.5em;
    z-index: 2;

    div {
        padding-left: 0;
        padding-right: 0;
    }
}

.intro-clients-list {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-top: 0.5em;
    list-style: none;
    opacity: 0.7;

    li {
        padding-bottom: 0.2em;
        padding-right: 0.8em;

        img {
            width: 80px;

            @media (max-width: $large-screen-size) {
                width: 65px;
            }
        }
    }
}

.intro-text {
    position: absolute;
    width: 100%;
    top: 35%;
    transform: perspective(1px) translate(0, -50%);
    left: 0;
    z-index: 2;

    div {
        padding-left: 0;
        padding-right: 0;
    }

    h1 {
        margin-bottom: 0;
    }

    p {
        line-height: 1.45em;
    }

    .intro-description {
        text-shadow: 2px 2px 4px $black;
    }
}

.intro-text-ideas {
    letter-spacing: -2px;
    opacity: 0.95;
}

// Small Devices
@media (max-width: $large-screen-size) {
    .intro-bg.intro-bg {
        left: 50%;
        right: auto;
        display: block;
        transform: perspective(1px) translate(-50%, 0);

        img {
            margin-top: 0;
            height: 60%;
        }

        .wrap {
            top: 35%;
        }
    }
    .intro-text h1 {
        font-size: 2.3em;
        margin-bottom: 0.2em;
    }

    .intro-text {
        transform: none;
        bottom: 7em;
        top: 40%;
    }

    .intro-text, .intro-clients {
        padding-left: 25px;
        padding-right: 25px;
    }

}
