.job-positions {
    padding-bottom: 2em;
}

.job-list {
    margin-bottom: 2em;
}

.jobs-desc {
    margin-top: 2em;
}

.jobs-title {
    margin-top: 2em;
}

.jobs-item-wrapper {
    margin-top: 1.5em;

    li {
        font-size: 0.85em;
        margin-bottom: 0.5em;
    }
}
