@import '../styles/_Variables.scss';

/* Approach Styles */
.approach {
}

// Small Devices
@media (max-width: $medium-screen-size) {
    .slides {
        height: 100%;

        h5 {
            font-size: 1.25em;
            line-height: 1.45em;
        }

        .carousel {
            width: 75%;
        }
    }
}
