.job-reasons {
    margin-top: 3.5em;
    margin-bottom: 3em;

    .job-reason {
        padding: 1em;
    }

    .job-img {
        display: block;
        margin: 1em auto 2.3em;
        width: 55%;
    }

    .job-divider {
        padding-top: 1em;
    }
}
