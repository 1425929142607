/* About Content Styles */
.about-container {
    section {
        margin-top: 2.5em;
    }

    .about-quotes {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        text-align: center;
    }

    .about-image {
        display: flex;
        margin-bottom: 3em;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 100%;
        }

        .equal-sign {
            transform: rotate(90deg);
        }
    }
}
